import * as React from "react"
// import React, { useState } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

//https://blog.logrocket.com/how-to-use-the-gsap-scrolltrigger-plugin-in-react/ 
// NOTE: look at using a single ref for container then pick items with element.querySelector("#id-of-thing")


gsap.registerPlugin(ScrollTrigger);


const Ardemo = () => {

  // store a reference to the box div
  const boxRef = useRef();
  const paraRef = useRef();
  const h1Ref = useRef();
  const headerRef = useRef();

  const showHeader = () => {
    //console.log('show header');
    headerRef.current.classList.remove('scrolled');
    headerRef.current.classList.add('reveal');

  }
  const hideHeader = () => {

    //console.log('hide header');
    headerRef.current.classList.add('scrolled');
    headerRef.current.classList.remove('reveal');
  }

  // wait until DOM has been rendered
  useEffect(() => {
    gsap.to(boxRef.current, { rotation: "+=360" });

    gsap.fromTo(paraRef.current,
      {
        opacity: 0,
        y: -20
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: h1Ref.current,
          start: "top top",
          end: "bottom center",
          scrub: true,
          //markers: true
        }
      }
    );

    // use gsap to add scroll listeners to body
    gsap.to(headerRef.current, {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: "body",
        start: "top -20px",
        end: "9999",
        toggleActions: "play none play none",
        onUpdate: (self) => {
          self.direction === 1 ? hideHeader() : showHeader();
        }
      }
    });

  });





  const handleClick = (e) => {
    //buttonhandler

    gsap.to(boxRef.current, { rotation: "+=360" });


  }


  return (
    <Layout>
      <Seo title="AR" />
      <div class="page-header bg-yellow" ref={headerRef}>
        <div class="header-container">
          <h1 ref={h1Ref}>3D demo <span role="img" aria-label="unicorn emoji">🦄</span></h1>
        </div>
      </div>

      <div className="page-body">
        <h2>Apple ArKit</h2>


        <p>Praesent in quam at nisl vulputate volutpat. Aenean a vulputate dui. Praesent suscipit hendrerit placerat. Nulla sodales elit eu eros aliquam laoreet. Vivamus mollis libero turpis. In mauris nunc, volutpat sit amet placerat ac, semper cursus est. Nulla malesuada pharetra dolor, vitae luctus ante pulvinar eu. Ut ex orci, placerat non dignissim eget, cursus nec dolor. Maecenas aliquet luctus erat, nec venenatis dui dignissim eget. Aenean sed finibus nibh. Aliquam eget consectetur erat. Ut porta, magna eget rhoncus posuere, sem mauris fermentum odio, et tincidunt turpis eros vel justo.

        </p>
        <p>Click the link below in iOS Safari for 3d demo <span role="img" aria-label="smiley">😊</span></p>

        <a href="https://humaan.co.uk/static/gramophone.usdz">Open the Gramaphone model</a>
        <a href="https://humaan.co.uk/static/cube-blender.usdz">Blender cube no materials</a>
        <a href="https://humaan.co.uk/static/fish-blender.usdz">Blender Fish no materials</a>

        <p>
          <button className="gsap-box" ref={boxRef} onClick={handleClick}>GSAP test</button>
        </p>
        <p ref={paraRef}>Praesent in quam at nisl vulputate volutpat. Aenean a vulputate dui. Praesent suscipit hendrerit placerat. Nulla sodales elit eu eros aliquam laoreet. Vivamus mollis libero turpis. In mauris nunc, volutpat sit amet placerat ac, semper cursus est. Nulla malesuada pharetra dolor, vitae luctus ante pulvinar eu. Ut ex orci, placerat non dignissim eget, cursus nec dolor. Maecenas aliquet luctus erat, nec venenatis dui dignissim eget. Aenean sed finibus nibh. Aliquam eget consectetur erat. Ut porta, magna eget rhoncus posuere, sem mauris fermentum odio, et tincidunt turpis eros vel justo.

        </p><p>Praesent in quam at nisl vulputate volutpat. Aenean a vulputate dui. Praesent suscipit hendrerit placerat. Nulla sodales elit eu eros aliquam laoreet. Vivamus mollis libero turpis. In mauris nunc, volutpat sit amet placerat ac, semper cursus est. Nulla malesuada pharetra dolor, vitae luctus ante pulvinar eu. Ut ex orci, placerat non dignissim eget, cursus nec dolor. Maecenas aliquet luctus erat, nec venenatis dui dignissim eget. Aenean sed finibus nibh. Aliquam eget consectetur erat. Ut porta, magna eget rhoncus posuere, sem mauris fermentum odio, et tincidunt turpis eros vel justo.

        </p>
        <p>Praesent in quam at nisl vulputate volutpat. Aenean a vulputate dui. Praesent suscipit hendrerit placerat. Nulla sodales elit eu eros aliquam laoreet. Vivamus mollis libero turpis. In mauris nunc, volutpat sit amet placerat ac, semper cursus est. Nulla malesuada pharetra dolor, vitae luctus ante pulvinar eu. Ut ex orci, placerat non dignissim eget, cursus nec dolor. Maecenas aliquet luctus erat, nec venenatis dui dignissim eget. Aenean sed finibus nibh. Aliquam eget consectetur erat. Ut porta, magna eget rhoncus posuere, sem mauris fermentum odio, et tincidunt turpis eros vel justo.

        </p>
        <p>Praesent in quam at nisl vulputate volutpat. Aenean a vulputate dui. Praesent suscipit hendrerit placerat. Nulla sodales elit eu eros aliquam laoreet. Vivamus mollis libero turpis. In mauris nunc, volutpat sit amet placerat ac, semper cursus est. Nulla malesuada pharetra dolor, vitae luctus ante pulvinar eu. Ut ex orci, placerat non dignissim eget, cursus nec dolor. Maecenas aliquet luctus erat, nec venenatis dui dignissim eget. Aenean sed finibus nibh. Aliquam eget consectetur erat. Ut porta, magna eget rhoncus posuere, sem mauris fermentum odio, et tincidunt turpis eros vel justo.

        </p>



      </div>

    </Layout>
  )
}

export default Ardemo
